import { FC } from "react";
import { ReservationDetailsDTO } from "../../../../../../store/endpoints/reservation-table.endpoints";
import { Grid, Paper } from "@mui/material";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { grey } from "@mui/material/colors";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import {
  getRestaurantReservationDate,
  getRestaurantReservationTime
} from "../reservation-details-restaurants/reservation-details-restaurants";

interface ReservationDetailsOverviewRestaurantsProps {
  reservation: ReservationDetailsDTO;
}

export const ReservationDetailsOverviewRestaurants: FC<
  ReservationDetailsOverviewRestaurantsProps
> = ({ reservation }) => {
  const { t } = useTranslationWrapper();

  return (
    <>
      <Grid container alignItems="center" pb={0.5} pr={1}>
        <Grid item xs={5} pl={1.5}>
          <ParagraphSmall color="text.primary">
            {t("labels__reservation__details__overview__restaurant_venue_name")}
          </ParagraphSmall>
        </Grid>
        <Grid item xs={2} textAlign="center">
          <ParagraphSmall color="text.primary">
            {t("labels__reservation__details__overview__restaurant_guests")}
          </ParagraphSmall>
        </Grid>
        <Grid item xs={5} textAlign="right" pr={1.5}>
          <ParagraphSmall color="text.primary">
            {t("labels__reservation__details__overview__restaurant_date_time")}
          </ParagraphSmall>
        </Grid>
      </Grid>
      <Grid container>
        {reservation.restaurantReservations.map((restaurantReservation) => (
          <Grid
            item
            xs={12}
            key={`${restaurantReservation?.foodAndBeveragePropertyId}_${restaurantReservation?.reservationDateTime}`}
            mb={1}
          >
            <Paper
              elevation={0}
              sx={{
                background: grey[100],
                borderColor: grey[100],
                borderRadius: "5px",
                p: 1.5
              }}
              variant="outlined"
            >
              <Grid container alignItems="center" pb={0.5} pr={1}>
                <Grid item xs={5}>
                  <ParagraphSmall color="text.primary">
                    {restaurantReservation?.foodAndBeveragePropertyName}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={2} textAlign="center">
                  <ParagraphSmall color="text.primary">
                    {restaurantReservation?.actualNumberOfGuests ??
                      restaurantReservation?.expectedNumberOfGuests ??
                      ""}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={5} textAlign="right">
                  <ParagraphSmall color="text.primary">
                    {getRestaurantReservationDate(restaurantReservation?.reservationDateTime ?? "")}
                    {" | "}
                    {getRestaurantReservationTime(restaurantReservation?.reservationDateTime ?? "")}
                  </ParagraphSmall>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
